import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 48,
    fontSize: 32,
    fontWeight: 400,
    padding: '0 12px',
    textAlign: 'center',
    fontFamily: 'Open Sans, sans-serif',
    color: '#525252',
    letterSpacing: '0px',
    lineHeight: '43px',
    '@media only screen and (max-width: 1024px)': {
      marginTop: 10
    },
    '@media only screen and (max-width: 520px)': {
      fontSize: 20,
      marginTop: 10,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '1.6px'
    }
  }
}));

const Section = ({ children, title, className, variant }) => {
  const classes = useStyles();

  return (
    <Box className={className}>
      {title && (
        <Typography className={classes.title} variant={variant}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string
};

Section.defaultProps = {
  children: null,
  title: null,
  className: null,
  variant: 'h2'
};

export default Section;
